define("ember-svg-jar/inlined/directions-run-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M12.125 4.37508C13.0416 4.37508 13.7916 3.62508 13.7916 2.70841C13.7916 1.79175 13.0416 1.04175 12.125 1.04175C11.2083 1.04175 10.4583 1.79175 10.4583 2.70841C10.4583 3.62508 11.2083 4.37508 12.125 4.37508ZM9.12496 15.9584L9.95829 12.2917L11.7083 13.9584V18.9584H13.375V12.7084L11.625 11.0417L12.125 8.54175C13.2083 9.79175 14.875 10.6251 16.7083 10.6251V8.95842C15.125 8.95842 13.7916 8.12508 13.125 6.95842L12.2916 5.62508C11.9583 5.12508 11.4583 4.79175 10.875 4.79175C10.625 4.79175 10.4583 4.87508 10.2083 4.87508L5.87496 6.70841V10.6251H7.54163V7.79175L9.04163 7.20842L7.70829 13.9584L3.62496 13.1251L3.29163 14.7917L9.12496 15.9584Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});