define("ember-svg-jar/inlined/history-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 24C8 14.06 16.06 6 26 6C35.94 6 44 14.06 44 24C44 33.94 35.94 42 26 42C21.02 42 16.54 39.98 13.28 36.72L16.12 33.88C18.64 36.42 22.14 38 26 38C33.74 38 40 31.74 40 24C40 16.26 33.74 10 26 10C18.26 10 12 16.26 12 24H18L10 31.98L2 24H8ZM24 26V16H27V24.3L34.04 28.48L32.5 31.04L24 26Z\"/>",
    "attrs": {
      "viewBox": "0 0 48 48",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});