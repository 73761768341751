define("ember-svg-jar/inlined/payment-pending-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M8.66663 10.1266V7.66665H9.66663V9.54665L11.2933 10.4866L10.7933 11.3533L8.66663 10.1266ZM11.6666 1.33331L10.6666 0.333313L9.66663 1.33331L8.66663 0.333313L7.66663 1.33331L6.66663 0.333313L5.66663 1.33331L4.66663 0.333313L3.66663 1.33331L2.66663 0.333313L1.66663 1.33331L0.666626 0.333313V13.6666L1.66663 12.6666L2.66663 13.6666L3.66663 12.6666L4.66663 13.6666L5.71996 12.6133C5.81329 12.74 5.91996 12.8533 6.03329 12.9666C6.90791 13.8282 8.08676 14.3105 9.31444 14.3089C10.5421 14.3074 11.7198 13.8222 12.5923 12.9585C13.4647 12.0948 13.9618 10.9221 13.9757 9.69452C13.9896 8.46691 13.5193 7.28325 12.6666 6.39998V0.333313L11.6666 1.33331ZM6.06663 6.33331C5.66663 6.71331 5.35329 7.16665 5.11329 7.66665H2.66663V6.33331H6.06663ZM4.71329 8.99998C4.66663 9.21998 4.66663 9.43998 4.66663 9.66665C4.66663 9.89331 4.66663 10.1133 4.71329 10.3333H2.66663V8.99998H4.71329ZM10.6666 4.99998H2.66663V3.66665H10.6666V4.99998ZM12.5666 9.66665C12.5666 10.0933 12.4866 10.5133 12.3333 10.9066C12.16 11.2933 11.92 11.6666 11.62 11.9533C11.3333 12.2533 10.96 12.4933 10.5733 12.6666C10.18 12.82 9.75996 12.9 9.33329 12.9C7.54663 12.9 6.09996 11.4533 6.09996 9.66665C6.09996 8.80665 6.43996 7.99998 7.04663 7.37998C7.66663 6.77331 8.47329 6.43331 9.33329 6.43331C11.1133 6.43331 12.5666 7.87998 12.5666 9.66665Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "14",
      "height": "15",
      "viewBox": "0 0 14 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});