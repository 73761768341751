define("ember-svg-jar/inlined/filter-outline-dot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.1711 3.17115C16.7327 1.60962 19.2673 1.60962 20.8289 3.17115C22.3904 4.73267 22.3904 7.26733 20.8289 8.82885C19.2673 10.3904 16.7327 10.3904 15.1711 8.82885C13.6096 7.26733 13.6096 4.73267 15.1711 3.17115Z\" fill=\"#0B80F3\"/>\n<path d=\"M14.7096 20.7075C14.9395 20.4976 15.0395 20.1776 14.9995 19.8777V10.7489V10.6116C14.6848 10.4065 14.3865 10.1658 14.1103 9.88968C13.9212 9.7005 13.7486 9.50094 13.5928 9.29282L12.9998 10.049V17.578L11.0002 15.5783V10.059L7.04082 4.99972H12.5911C12.72 4.29853 12.9857 3.61762 13.3881 3H5.00116C4.78119 3 4.57123 3.07999 4.38126 3.21997C3.95133 3.55992 3.87134 4.18983 4.21129 4.61977L9.0005 10.7489V15.8682C8.9605 16.1582 9.06049 16.4681 9.29045 16.6981L13.2998 20.7075C13.6897 21.0975 14.3196 21.0975 14.7096 20.7075Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});