define("ember-svg-jar/inlined/arrow-outward-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M4.875 4.125V5.625H11.3175L4.125 12.8175L5.1825 13.875L12.375 6.6825V13.125H13.875V4.125H4.875Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});