define("ember-svg-jar/inlined/change-order-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M18 2L14 6H17V12C17 12.565 16.565 13 16 13H9V15H16C17.645 15 19 13.645 19 12V6H22L18 2ZM8 9C6.35503 9 5 10.355 5 12V18H2L6 22L10 18H7V12C7 11.435 7.43497 11 8 11H15V9H8Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});