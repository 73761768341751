define("ember-svg-jar/inlined/task-list-table-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M12.6 2.75008H9.81333C9.53333 1.97675 8.8 1.41675 7.93333 1.41675C7.06667 1.41675 6.33333 1.97675 6.05333 2.75008H3.26667C3.17333 2.75008 3.08667 2.75675 3 2.77675C2.74 2.83008 2.57333 2.79675 2.39333 2.97675C2.27333 3.09675 2.17333 3.24341 2.10667 3.40341C2.04 3.55675 2 3.73008 2 3.91675V13.2501C2 13.4301 2.04 13.6101 2.10667 13.7701C2.17333 13.9301 2.27333 14.0701 2.39333 14.1967C2.57333 14.3767 2.80667 14.5101 3.06667 14.5634C3.15333 14.5767 3.24 14.5834 3.33333 14.5834H12.6667C13.4 14.5834 14 13.9834 14 13.2501V3.91675C14 3.18341 13.3333 2.75008 12.6 2.75008ZM7.93333 2.2644C8.19089 2.2644 8.41016 2.48341 8.41016 2.75008C8.41016 2.98602 8.21042 3.20972 7.93333 3.20972C7.65625 3.20972 7.46484 2.97032 7.46484 2.75008C7.46484 2.48341 7.67578 2.2644 7.93333 2.2644ZM12.7633 13.5888L8 13.5888L3.23215 13.5888V8.53495V3.69028H8H12.7633V8.75278V13.5888Z\" fill=\"#4B5565\"/>\n<path d=\"M7.5 5.41683H11.5V6.75016H7.5V5.41683Z\" fill=\"#4B5565\"/>\n<path d=\"M7.5 8.0835H11.5V9.41683H7.5V8.0835Z\" fill=\"#4B5565\"/>\n<path d=\"M7.5 10.7502H11.5V12.0835H7.5V10.7502Z\" fill=\"#4B5565\"/>\n<path d=\"M4.83333 5.41683H6.16667V6.75016H4.83333V5.41683Z\" fill=\"#4B5565\"/>\n<path d=\"M4.83333 8.0835H6.16667V9.41683H4.83333V8.0835Z\" fill=\"#4B5565\"/>\n<path d=\"M4.83333 10.7502H6.16667V12.0835H4.83333V10.7502Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});