define("ember-svg-jar/inlined/star-o-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.81 9.62L22 10.24L16.55 14.97L18.18 22L12 18.27L5.82 22L7.46 14.97L2 10.24L9.19 9.63L12 3L14.81 9.62ZM8.24 18.67L12 16.4L15.77 18.68L14.77 14.4L18.09 11.52L13.71 11.14L12 7.1L10.3 11.13L5.92 11.51L9.24 14.39L8.24 18.67Z\" fill=\"#BEBEBE\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});