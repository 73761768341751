define("ember-svg-jar/inlined/calendar-with-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.8333 2.50004H15V0.833374H13.3333V2.50004H6.66667V0.833374H5V2.50004H4.16667C3.24167 2.50004 2.50833 3.25004 2.50833 4.16671L2.5 15.8334C2.5 16.75 3.24167 17.5 4.16667 17.5H15.8333C16.75 17.5 17.5 16.75 17.5 15.8334V4.16671C17.5 3.25004 16.75 2.50004 15.8333 2.50004ZM13.775 9.21671L12.8917 8.33337L8.825 12.4L7.05833 10.6334L6.175 11.5167L8.825 14.1667L13.775 9.21671ZM4.16667 15.8334H15.8333V6.66671H4.16667V15.8334Z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
});