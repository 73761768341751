define("ember-svg-jar/inlined/build-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M22.7444 18.9002L13.6444 9.80016C14.5444 7.50016 14.0444 4.80016 12.1444 2.90016C10.1444 0.900159 7.14437 0.500159 4.74437 1.60016L9.04437 5.90016L6.04437 8.90016L1.64437 4.60016C0.444373 7.00016 0.944373 10.0002 2.94437 12.0002C4.84437 13.9002 7.54437 14.4002 9.84437 13.5002L18.9444 22.6002C19.3444 23.0002 19.9444 23.0002 20.3444 22.6002L22.6444 20.3002C23.1444 19.9002 23.1444 19.2002 22.7444 18.9002Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});