define("ember-svg-jar/inlined/task-list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect width=\"48\" height=\"48\" rx=\"24\" fill=\"#FDEDEB\"/>\n<path d=\"M17.3337 24.8333C16.4087 24.8333 15.667 25.575 15.667 26.5V29.8333C15.667 30.7583 16.4087 31.5 17.3337 31.5H20.667C21.592 31.5 22.3337 30.7583 22.3337 29.8333V26.5C22.3337 25.575 21.592 24.8333 20.667 24.8333H17.3337ZM20.8337 26.0833L21.717 26.9583L18.392 30.25L16.2837 28.125L17.1753 27.25L18.4003 28.4917L20.8337 26.0833ZM17.3337 16.5C16.4087 16.5 15.667 17.2417 15.667 18.1667V21.5C15.667 22.425 16.4087 23.1667 17.3337 23.1667H20.667C21.592 23.1667 22.3337 22.425 22.3337 21.5V18.1667C22.3337 17.2417 21.592 16.5 20.667 16.5H17.3337ZM17.3337 18.1667H20.667V21.5H17.3337V18.1667ZM24.0003 18.1667H32.3337V19.8333H24.0003V18.1667ZM24.0003 29.8333V28.1667H32.3337V29.8333H24.0003ZM24.0003 23.1667H32.3337V24.8333H24.0003V23.1667Z\" fill=\"#E54234\"/>\n",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});