define("ember-svg-jar/inlined/anyone-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect width=\"26\" height=\"26\" rx=\"13\" fill=\"#EBEFF2\"/>\n<g opacity=\"0.08\">\n<rect x=\"0.25\" y=\"0.25\" width=\"25.5\" height=\"25.5\" rx=\"12.75\" stroke=\"#121926\" stroke-width=\"0.5\"/>\n</g>\n<path d=\"M15.6665 12.3335C16.7732 12.3335 17.6598 11.4402 17.6598 10.3335C17.6598 9.22683 16.7732 8.3335 15.6665 8.3335C14.5598 8.3335 13.6665 9.22683 13.6665 10.3335C13.6665 11.4402 14.5598 12.3335 15.6665 12.3335ZM10.3332 12.3335C11.4398 12.3335 12.3265 11.4402 12.3265 10.3335C12.3265 9.22683 11.4398 8.3335 10.3332 8.3335C9.2265 8.3335 8.33317 9.22683 8.33317 10.3335C8.33317 11.4402 9.2265 12.3335 10.3332 12.3335ZM10.3332 13.6668C8.77984 13.6668 5.6665 14.4468 5.6665 16.0002V17.6668H14.9998V16.0002C14.9998 14.4468 11.8865 13.6668 10.3332 13.6668ZM15.6665 13.6668C15.4732 13.6668 15.2532 13.6802 15.0198 13.7002C15.7932 14.2602 16.3332 15.0135 16.3332 16.0002V17.6668H20.3332V16.0002C20.3332 14.4468 17.2198 13.6668 15.6665 13.6668Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});