define("ember-svg-jar/inlined/forward-5-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M15 11.3333C15 14.0916 12.7583 16.3333 10 16.3333C7.24167 16.3333 5 14.0916 5 11.3333C5 8.57498 7.24167 6.33331 10 6.33331V9.66665L14.1667 5.49998L10 1.33331V4.66665C6.31666 4.66665 3.33333 7.64998 3.33333 11.3333C3.33333 15.0166 6.31666 18 10 18C13.6833 18 16.6667 15.0166 16.6667 11.3333H15Z\" fill=\"white\"/>\n<path d=\"M9.55962 12.3472L9.10434 12.2358L9.29167 10.5H11.1531V10.9837H9.76592L9.67107 11.8303C9.72482 11.7987 9.79912 11.7678 9.89397 11.7378C9.98882 11.7062 10.0971 11.6904 10.2188 11.6904C10.3817 11.6904 10.5271 11.7173 10.6551 11.771C10.7848 11.8232 10.8946 11.8998 10.9848 12.001C11.0749 12.1006 11.1436 12.2223 11.1911 12.3662C11.2385 12.5085 11.2622 12.6689 11.2622 12.8476C11.2622 13.0072 11.2385 13.1566 11.1911 13.2957C11.1452 13.4348 11.0757 13.5574 10.9824 13.6633C10.8891 13.7692 10.7713 13.8522 10.6291 13.9123C10.4884 13.9708 10.3216 14 10.1287 14C9.98487 14 9.84654 13.9794 9.71375 13.9383C9.58254 13.8957 9.46477 13.8332 9.36043 13.751C9.2561 13.6672 9.17231 13.5645 9.10908 13.4428C9.04584 13.3194 9.00949 13.178 9 13.0183H9.55962C9.57385 13.1305 9.60467 13.2262 9.6521 13.3052C9.70111 13.3827 9.76592 13.442 9.84654 13.4831C9.92717 13.5242 10.0204 13.5447 10.1264 13.5447C10.2228 13.5447 10.3058 13.5281 10.3753 13.4949C10.4449 13.4601 10.5026 13.4111 10.5484 13.3479C10.5959 13.2831 10.6306 13.2072 10.6528 13.1203C10.6765 13.0333 10.6883 12.9369 10.6883 12.831C10.6883 12.7298 10.6749 12.6373 10.648 12.5535C10.6227 12.4697 10.584 12.397 10.5318 12.3354C10.4813 12.2737 10.4172 12.2263 10.3398 12.1931C10.2623 12.1583 10.173 12.1409 10.0718 12.1409C9.93586 12.1409 9.83153 12.1607 9.75881 12.2002C9.68767 12.2397 9.62127 12.2887 9.55962 12.3472Z\" fill=\"white\"/>\n",
    "attrs": {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});