define("ember-svg-jar/inlined/reminder-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.8184 5.75027L18.2114 1.90527L16.9284 3.44227L21.5384 7.28527L22.8184 5.75027ZM8.8194 3.44127L7.5374 1.90527L2.9314 5.74927L4.2114 7.28527L8.8194 3.44127ZM3.8744 13.0953C3.8744 8.12527 7.9044 4.09527 12.8744 4.09527C17.8444 4.09527 21.8744 8.12527 21.8744 13.0953C21.8744 18.0653 17.8444 22.0953 12.8744 22.0953C7.9044 22.0953 3.8744 18.0653 3.8744 13.0953ZM5.8744 13.0953C5.8744 16.9553 9.0144 20.0953 12.8744 20.0953C16.7344 20.0953 19.8744 16.9553 19.8744 13.0953C19.8744 9.23527 16.7344 6.09527 12.8744 6.09527C9.0144 6.09527 5.8744 9.23527 5.8744 13.0953ZM16.875 14H8.875V12H16.875V14Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 25 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});