define("ember-svg-jar/inlined/copy-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.3551 1.6665H4.40773C3.58756 1.6665 2.9165 2.34832 2.9165 3.18166V13.7877H4.40773V3.18166H13.3551V1.6665Z\"/><path d=\"M15.5919 4.69681H7.39019C6.57001 4.69681 5.89896 5.37862 5.89896 6.21196V16.818C5.89896 17.6514 6.57001 18.3332 7.39019 18.3332H15.5919C16.4121 18.3332 17.0832 17.6514 17.0832 16.818V6.21196C17.0832 5.37862 16.4121 4.69681 15.5919 4.69681Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});