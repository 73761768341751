define("ember-svg-jar/inlined/check-circle-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14 3.908L7.154 10.76L4.61 8.216L5.456 7.37L7.154 9.068L13.154 3.068L14 3.908ZM12.674 6.932C12.752 7.274 12.8 7.634 12.8 8C12.8 10.652 10.652 12.8 8 12.8C5.348 12.8 3.2 10.652 3.2 8C3.2 5.348 5.348 3.2 8 3.2C8.948 3.2 9.824 3.476 10.568 3.95L11.432 3.086C10.46 2.402 9.278 2 8 2C4.688 2 2 4.688 2 8C2 11.312 4.688 14 8 14C11.312 14 14 11.312 14 8C14 7.286 13.868 6.602 13.64 5.966L12.674 6.932Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});