define("ember-svg-jar/inlined/add-date-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<g clip-path=\"url(#clip0_1752_24299)\">\n<path d=\"M4.375 22C3.72187 22 3.16296 21.7717 2.69825 21.3152C2.23354 20.8586 2.00079 20.3091 2 19.6667V5.66667C2 5.025 2.23275 4.47589 2.69825 4.01933C3.16375 3.56278 3.72267 3.33411 4.375 3.33333H5.5625V1H7.9375V3.33333H15.0625V1H17.4375V3.33333H18.625C19.2781 3.33333 19.8374 3.562 20.3029 4.01933C20.7684 4.47667 21.0008 5.02578 21 5.66667V12.7833C20.6042 12.725 20.2083 12.6958 19.8125 12.6958C19.4167 12.6958 19.0208 12.725 18.625 12.7833V10.3333H4.375V19.6667H12.6875C12.6875 20.0556 12.7172 20.4444 12.7766 20.8333C12.8359 21.2222 12.9448 21.6111 13.1031 22H4.375ZM4.375 8H18.625V5.66667H4.375V8Z\" fill=\"#4B5565\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.2544 19.6982V23.36H19.9449V19.6982L23.6069 19.6982L23.6069 18.0078L19.9449 18.0078L19.9449 14.3454H18.2544L18.2544 18.0078L14.5923 18.0078L14.5923 19.6982L18.2544 19.6982Z\" fill=\"#4B5565\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_1752_24299\">\n<rect width=\"24\" height=\"24\" fill=\"white\"/>\n</clipPath>\n</defs>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});