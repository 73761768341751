define("ember-svg-jar/inlined/video-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11 2.04999V4.06999C9.54005 4.24999 8.21005 4.82999 7.10005 5.68999L5.68005 4.25999C7.16005 3.04999 8.99005 2.24999 11 2.04999ZM13.05 9.78999L10 7.49999V16.5L13.05 14.21L16 12L13.05 9.78999ZM5.69005 7.09999L4.26005 5.67999C3.05005 7.15999 2.25005 8.98999 2.05005 11H4.07005C4.25005 9.53999 4.83005 8.20999 5.69005 7.09999ZM2.05005 13H4.07005C4.25005 14.46 4.83005 15.79 5.69005 16.89L4.26005 18.32C3.05005 16.84 2.25005 15.01 2.05005 13ZM5.68005 19.74C7.16005 20.95 9.00005 21.75 11 21.95V19.93C9.54005 19.75 8.21005 19.17 7.10005 18.31L5.68005 19.74ZM13.05 21.95C18.08 21.42 22 17.16 22 12C22 6.83999 18.08 2.57999 13.05 2.04999V4.06999C16.97 4.58999 20 7.94999 20 12C20 16.05 16.97 19.41 13.05 19.93V21.95Z\" fill=\"white\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});