define("ember-svg-jar/inlined/edit-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4 16.666V20h3.334l9.837-9.837-3.334-3.334L4 16.666zm15.74-9.072a.89.89 0 0 0 0-1.258L17.664 4.26a.89.89 0 0 0-1.258 0L14.78 5.887l3.334 3.334 1.627-1.627z\" fill=\"#7C849B\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});