define("ember-svg-jar/inlined/access-time-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.99199 1.66675C5.39199 1.66675 1.66699 5.40008 1.66699 10.0001C1.66699 14.6001 5.39199 18.3334 9.99199 18.3334C14.6003 18.3334 18.3337 14.6001 18.3337 10.0001C18.3337 5.40008 14.6003 1.66675 9.99199 1.66675ZM10.0003 16.6667C6.31699 16.6667 3.33366 13.6834 3.33366 10.0001C3.33366 6.31675 6.31699 3.33341 10.0003 3.33341C13.6837 3.33341 16.667 6.31675 16.667 10.0001C16.667 13.6834 13.6837 16.6667 10.0003 16.6667ZM9.16699 5.83341H10.417V10.2084L14.167 12.4334L13.542 13.4584L9.16699 10.8334V5.83341Z\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});