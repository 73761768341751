define("ember-svg-jar/inlined/sort-asc-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.6663 11.3333H14.6663L11.9997 14L9.33301 11.3333H11.333V2H12.6663V11.3333ZM1.33301 11.3333H7.99967V12.6667H1.33301V11.3333ZM3.99967 3.33333V4.66667H1.33301V3.33333H3.99967ZM1.33301 7.33333H5.99967V8.66667H1.33301V7.33333Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});