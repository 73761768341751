define("ember-svg-jar/inlined/custom-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.82737 9.80233C1.29027 9.40246 1.29027 8.59798 1.82737 8.19811L3.25657 7.13407L2.72575 5.43318C2.52627 4.79397 2.99913 4.14314 3.66869 4.13534L5.45036 4.11457L6.02068 2.42651C6.23501 1.79213 7.00011 1.54354 7.54639 1.93078L9 2.96122L10.4536 1.93078C10.9999 1.54354 11.765 1.79213 11.9793 2.42652L12.5496 4.11457L14.3313 4.13534C15.0009 4.14314 15.4737 4.79398 15.2742 5.43318L14.7434 7.13407L16.1726 8.19811C16.7097 8.59798 16.7097 9.40246 16.1726 9.80233L14.7434 10.8664L15.2742 12.5673C15.4737 13.2065 15.0009 13.8573 14.3313 13.8651L12.5496 13.8859L11.9793 15.5739C11.765 16.2083 10.9999 16.4569 10.4536 16.0697L9 15.0392L7.54639 16.0697C7.00011 16.4569 6.23501 16.2083 6.02068 15.5739L5.45036 13.8859L3.66869 13.8651C2.99913 13.8573 2.52627 13.2065 2.72576 12.5673L3.25657 10.8664L1.82737 9.80233Z\" fill=\"#9AA4B2\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});