define("ember-svg-jar/inlined/sort-descending", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.6667 4.66667H14.6667L12 2L9.33337 4.66667H11.3334V14H12.6667V4.66667ZM1.33337 11.3333H8.00004V12.6667H1.33337V11.3333ZM4.00004 3.33333V4.66667H1.33337V3.33333H4.00004ZM1.33337 7.33333H6.00004V8.66667H1.33337V7.33333Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});