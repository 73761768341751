define("ember-svg-jar/inlined/monetization-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12ZM13.41 20V18.09C15.15 17.76 16.52 16.76 16.53 14.93C16.53 12.41 14.37 11.54 12.35 11.02C10.33 10.5 9.68 9.95 9.68 9.11C9.68 8.15 10.58 7.47 12.08 7.47C13.66 7.47 14.25 8.23 14.3 9.34H16.26C16.2 7.81 15.27 6.4 13.41 5.95V4H10.74V5.93C9.02 6.3 7.63 7.42 7.63 9.14C7.63 11.19 9.33 12.21 11.81 12.81C14.04 13.34 14.48 14.12 14.48 14.95C14.48 15.56 14.04 16.54 12.08 16.54C10.25 16.54 9.53 15.72 9.43 14.67H7.47C7.58 16.61 9.03 17.71 10.74 18.07V20H13.41Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});