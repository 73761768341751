define("ember-svg-jar/inlined/turn-right-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M15.17 10L13.58 11.59L15 13L19 9L15 5L13.59 6.41L15.17 8H7C5.9 8 5 8.9 5 10V19H7V10H15.17Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});