define("ember-svg-jar/inlined/non-taxed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M4.64392 4.5V5.75L5.14392 5L4.64392 4.5Z\" fill=\"#4B5565\"/>\n<path d=\"M8.14392 5.87868V5H7.26524L5.26524 3H14.3939V5H10.1439V7.87868L8.14392 5.87868Z\" fill=\"#4B5565\"/>\n<path d=\"M8.14392 8V15H10.1439V10L8.14392 8Z\" fill=\"#4B5565\"/>\n<path d=\"M3.58191 5.5415L4.64257 4.48084L14.5259 14.3641L13.4652 15.4248L3.58191 5.5415Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 19 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});