define("ember-svg-jar/inlined/label-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17.4 5.5158C17.0211 4.97896 16.3895 4.63159 15.6842 4.63159L4.10526 4.64212C2.94737 4.64212 2 5.57896 2 6.73686V17.2632C2 18.4211 2.94737 19.3579 4.10526 19.3579L15.6842 19.3684C16.3895 19.3684 17.0211 19.0211 17.4 18.4842L22 12L17.4 5.5158Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});