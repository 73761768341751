define("ember-svg-jar/inlined/cash-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.5 3V15H16.5V3H1.5ZM5.25 6H3.75V12H5.25V6ZM6.75 12H9C9.4125 12 9.75 11.6625 9.75 11.25V6.75C9.75 6.3375 9.4125 6 9 6H6.75C6.3375 6 6 6.3375 6 6.75V11.25C6 11.6625 6.3375 12 6.75 12ZM11.25 12H13.5C13.9125 12 14.25 11.6625 14.25 11.25V6.75C14.25 6.3375 13.9125 6 13.5 6H11.25C10.8375 6 10.5 6.3375 10.5 6.75V11.25C10.5 11.6625 10.8375 12 11.25 12ZM7.5 7.5H8.25V10.5H7.5V7.5ZM12 7.5H12.75V10.5H12V7.5Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});