define("ember-svg-jar/inlined/briefcase-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.817 6.591V4.774h-3.634v1.817h3.634zm5.409 0c.505 0 .927.177 1.266.53.339.354.508.783.508 1.288v9.887c0 .504-.17.934-.508 1.287-.34.353-.761.53-1.266.53H4.774c-.505 0-.927-.177-1.266-.53A1.791 1.791 0 0 1 3 18.296V8.409c0-.505.17-.934.508-1.288.34-.353.761-.53 1.266-.53h3.635V4.774c0-.505.17-.927.508-1.266.339-.339.76-.508 1.266-.508h3.634c.505 0 .927.17 1.266.508.339.34.508.761.508 1.266v1.817h3.635z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});