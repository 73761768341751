define("ember-svg-jar/inlined/alarm-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.60173 2.93447L5.52805 1.66666L1.66699 4.83958L2.74059 6.1074L6.60173 2.93447ZM14.4725 1.66717L18.3337 4.84009L17.26 6.10791L13.3989 2.93498L14.4725 1.66717ZM10.4194 6.77621H9.16233V11.7293L13.143 14.082L13.7715 13.0666L10.4194 11.1101V6.77621ZM10.0004 3.47421C5.83536 3.47421 2.4581 6.80101 2.4581 10.9038C2.4581 15.0066 5.83536 18.3334 10.0004 18.3334C14.1654 18.3334 17.5426 15.0066 17.5426 10.9038C17.5426 6.80101 14.1654 3.47421 10.0004 3.47421ZM4.13419 10.9038C4.13419 14.0902 6.7656 16.6823 10.0004 16.6823C13.2352 16.6823 15.8666 14.0902 15.8666 10.9038C15.8666 7.71731 13.2352 5.12521 10.0004 5.12521C6.7656 5.12521 4.13419 7.71731 4.13419 10.9038Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});