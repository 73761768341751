define("ember-svg-jar/inlined/add-priority-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<g clip-path=\"url(#clip0_1780_15094)\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.625 3.875V1.875H4.375V22.125H6.625V14.125H12.6932C13.8821 12.8194 15.5956 12 17.5005 12C17.738 12 17.9726 12.0127 18.2036 12.0376L16.1153 9L18.4271 5.63733C18.6637 5.29306 18.6901 4.84599 18.4956 4.47625C18.3011 4.10651 17.9178 3.875 17.5 3.875H6.625ZM15.3614 11.875H6.625V6.125H15.3614L13.8231 8.36267C13.5591 8.74657 13.5591 9.25343 13.8231 9.63733L15.3614 11.875Z\" fill=\"#9AA4B2\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.4556 19.5506V23.2125H18.146V19.5506L21.8081 19.5506L21.8081 17.8602L18.146 17.8602L18.146 14.1978H16.4556L16.4556 17.8602L12.7934 17.8602L12.7934 19.5506L16.4556 19.5506Z\" fill=\"#9AA4B2\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_1780_15094\">\n<rect width=\"24\" height=\"24\" fill=\"white\"/>\n</clipPath>\n</defs>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});