define("ember-svg-jar/inlined/signature-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<mask id=\"mask0_589_26328\" style=\"mask-type:alpha\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"25\" height=\"24\">\n<rect x=\"0.507812\" width=\"24\" height=\"24\" fill=\"#D9D9D9\"/>\n</mask>\n<g mask=\"url(#mask0_589_26328)\">\n<path d=\"M8.50781 20C7.95781 20 7.48698 19.8042 7.09531 19.4125C6.70365 19.0208 6.50781 18.55 6.50781 18V15H9.50781V12.75C8.92448 12.7167 8.37031 12.5875 7.84531 12.3625C7.32031 12.1375 6.84115 11.8 6.40781 11.35V10.25H5.25781L2.00781 7C2.60781 6.23333 3.34948 5.69167 4.23281 5.375C5.11615 5.05833 6.00781 4.9 6.90781 4.9C7.35781 4.9 7.79531 4.93333 8.22031 5C8.64531 5.06667 9.07448 5.19167 9.50781 5.375V4H21.5078V17C21.5078 17.8333 21.2161 18.5417 20.6328 19.125C20.0495 19.7083 19.3411 20 18.5078 20H8.50781ZM11.5078 15H17.5078V17C17.5078 17.2833 17.6036 17.5208 17.7953 17.7125C17.987 17.9042 18.2245 18 18.5078 18C18.7911 18 19.0286 17.9042 19.2203 17.7125C19.412 17.5208 19.5078 17.2833 19.5078 17V6H11.5078V6.6L17.5078 12.6V14H16.1078L13.2578 11.15L13.0578 11.35C12.8245 11.5833 12.5786 11.7917 12.3203 11.975C12.062 12.1583 11.7911 12.3 11.5078 12.4V15ZM6.10781 8.25H8.40781V10.4C8.60781 10.5333 8.81615 10.625 9.03281 10.675C9.24948 10.725 9.47448 10.75 9.70781 10.75C10.0911 10.75 10.437 10.6917 10.7453 10.575C11.0536 10.4583 11.3578 10.25 11.6578 9.95L11.8578 9.75L10.4578 8.35C9.97448 7.86667 9.43281 7.50417 8.83281 7.2625C8.23281 7.02083 7.59115 6.9 6.90781 6.9C6.57448 6.9 6.25781 6.925 5.95781 6.975C5.65781 7.025 5.35781 7.1 5.05781 7.2L6.10781 8.25Z\" fill=\"#0B80F3\"/>\n</g>\n",
    "attrs": {
      "viewBox": "0 0 25 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});