define("ember-svg-jar/inlined/duplicate-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M15 3H7.5V4.5H6V3C6 2.17157 6.67157 1.5 7.5 1.5H15C15.8284 1.5 16.5 2.17157 16.5 3V10.5C16.5 11.3284 15.8284 12 15 12H13.5V10.5H15V3Z\" fill=\"#4B5565\"/>\n<path d=\"M3 6C2.17157 6 1.5 6.67157 1.5 7.5V15C1.5 15.8284 2.17157 16.5 3 16.5H10.5C11.3284 16.5 12 15.8284 12 15V7.5C12 6.67157 11.3284 6 10.5 6H3Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});