define("ember-svg-jar/inlined/caret-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1152 896q0 26-19 45l-448 448q-19 19-45 19t-45-19-19-45v-896q0-26 19-45t45-19 45 19l448 448q19 19 19 45z\"/>",
    "attrs": {
      "height": "1792",
      "viewBox": "0 0 1792 1792",
      "width": "1792",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});