define("ember-svg-jar/inlined/checkbox-circle-custom-hover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect x=\"0.5\" y=\"0.5\" width=\"23\" height=\"23\" rx=\"11.5\" fill=\"url(#paint0_linear_1053_10266)\" stroke=\"#53B0FD\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M19.1338 7.3661C19.6219 7.85424 19.6219 8.64566 19.1338 9.1338L10.8838 17.3838C10.3957 17.8719 9.60424 17.8719 9.1161 17.3838L5.3661 13.6338C4.87797 13.1457 4.87797 12.3542 5.3661 11.8661C5.85424 11.378 6.64566 11.378 7.1338 11.8661L9.99995 14.7323L17.3661 7.3661C17.8542 6.87797 18.6457 6.87797 19.1338 7.3661Z\" fill=\"#B2DDFF\"/>\n<defs>\n<linearGradient id=\"paint0_linear_1053_10266\" x1=\"12\" y1=\"0\" x2=\"12\" y2=\"24\" gradientUnits=\"userSpaceOnUse\">\n<stop stop-color=\"#EDF1F5\"/>\n<stop offset=\"1\" stop-color=\"white\"/>\n</linearGradient>\n</defs>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});