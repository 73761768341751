define("ember-svg-jar/inlined/assigned-users-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<g clip-path=\"url(#clip0_9413_329906)\">\n<rect width=\"44\" height=\"44\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22 0C9.856 0 0 9.856 0 22C0 34.144 9.856 44 22 44C34.144 44 44 34.144 44 22C44 9.856 34.144 0 22 0ZM29.942 13.948C32.296 13.948 34.188 15.84 34.188 18.194C34.188 20.548 32.296 22.44 29.942 22.44C27.588 22.44 25.696 20.548 25.696 18.194C25.674 15.84 27.588 13.948 29.942 13.948ZM21.934 15.664C21.934 12.804 19.602 10.472 16.742 10.472C13.86 10.472 11.55 12.782 11.55 15.664C11.55 18.524 13.882 20.856 16.742 20.856C19.602 20.856 21.934 18.524 21.934 15.664ZM16.742 30.558V38.808C11.462 37.158 7.282 33.088 5.434 27.896C7.744 25.432 13.508 24.178 16.742 24.178C17.908 24.178 19.382 24.354 20.922 24.662C17.314 26.576 16.742 29.106 16.742 30.558ZM20.262 39.512C20.834 39.578 21.406 39.6 22 39.6C29.458 39.6 35.816 34.936 38.39 28.402C36.366 26.73 32.296 25.872 29.942 25.872C26.73 25.872 20.262 27.434 20.262 30.558V39.512Z\" fill=\"#7C849B\"/>\n</g>\n<defs>\n<clipPath id=\"clip0_9413_329906\">\n<rect width=\"44\" height=\"44\" fill=\"white\"/>\n</clipPath>\n</defs>\n",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});