define("ember-svg-jar/inlined/desktop-access-disabled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.41 1.68994L0 3.09994L0.99 4.08994V15.9999C0.99 17.0999 1.89 17.9999 2.99 17.9999H10V19.9999H8V21.9999H16V19.9999H14V17.9999H14.9L20.9 23.9999L22.31 22.5899L1.41 1.68994ZM2.99 15.9999V6.08994L12.9 15.9999H2.99ZM6.55 3.99994L4.55 1.99994H20.99C22.09 1.99994 22.99 2.89994 22.99 3.99994V15.9999C22.99 17.0999 22.09 17.9999 20.99 17.9999H20.55L18.55 15.9999H20.99V3.99994H6.55Z\" fill=\"#FC6803\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});