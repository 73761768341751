define("ember-svg-jar/inlined/sticky-note-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21 16.15V5C21 3.89543 20.1046 3 19 3H5C3.89543 3 3 3.89543 3 5V19C3 20.1046 3.89543 21 5 21H16.15L21 16.15ZM15 15H16.2H19.5L15 19.5V15ZM17 7H7V9H17V7ZM7 11H17V13H7V11ZM12 15H7V17H12V15Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});