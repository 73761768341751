define("ember-svg-jar/inlined/url-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M2.925 9C2.925 7.7175 3.9675 6.675 5.25 6.675H8.25V5.25H5.25C3.18 5.25 1.5 6.93 1.5 9C1.5 11.07 3.18 12.75 5.25 12.75H8.25V11.325H5.25C3.9675 11.325 2.925 10.2825 2.925 9ZM6 9.75H12V8.25H6V9.75ZM12.75 5.25H9.75V6.675H12.75C14.0325 6.675 15.075 7.7175 15.075 9C15.075 10.2825 14.0325 11.325 12.75 11.325H9.75V12.75H12.75C14.82 12.75 16.5 11.07 16.5 9C16.5 6.93 14.82 5.25 12.75 5.25Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});