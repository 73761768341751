define("ember-svg-jar/inlined/leading-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M13.1649 8.5C10.5149 8.5 8.11492 9.49 6.26492 11.1L2.66492 7.5V16.5H11.6649L8.04492 12.88C9.43492 11.72 11.2049 11 13.1649 11C16.7049 11 19.7149 13.31 20.7649 16.5L23.1349 15.72C21.7449 11.53 17.8149 8.5 13.1649 8.5Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 25 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});