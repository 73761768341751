define("ember-svg-jar/inlined/mention-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M16.7368 18.5464H4.10526V5.6832H11.4737V3.57794H4.10526C2.94737 3.57794 2 4.52531 2 5.6832V18.3148C2 19.4727 2.94737 20.42 4.10526 20.42H16.7368C17.8947 20.42 18.8421 19.4727 18.8421 18.3148V10.9464H16.7368V18.5464Z\" fill=\"#7C849B\"/>\n<path d=\"M18.8421 0.420044H16.7368V3.57794H13.5789C13.5895 3.58847 13.5789 5.6832 13.5789 5.6832H16.7368V8.83057C16.7474 8.8411 18.8421 8.83057 18.8421 8.83057V5.6832H22V3.57794H18.8421V0.420044Z\" fill=\"#7C849B\"/>\n<path d=\"M14.6316 7.78847H6.21053V9.89373H14.6316V7.78847Z\" fill=\"#7C849B\"/>\n<path d=\"M6.21053 10.9464V13.0516H14.6316V10.9464H6.21053Z\" fill=\"#7C849B\"/>\n<path d=\"M14.6316 14.1043H6.21053V16.2095H14.6316V14.1043Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});