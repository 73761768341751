define("ember-svg-jar/inlined/calendar-with-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M17 12.966h-5v4.835h5zM16 2.334v1.932H8V2.333H6v1.933H5c-1.11 0-1.99.87-1.99 1.935L3 19.734c0 1.062.89 1.933 2 1.933h14c1.1 0 2-.87 2-1.933V6.2c0-1.065-.9-1.935-2-1.935h-1V2.333zm3 17.4H5V9.1h14z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});