define("ember-svg-jar/inlined/people-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M12 8.25C13.245 8.25 14.2425 7.245 14.2425 6C14.2425 4.755 13.245 3.75 12 3.75C10.755 3.75 9.75 4.755 9.75 6C9.75 7.245 10.755 8.25 12 8.25ZM6 8.25C7.245 8.25 8.2425 7.245 8.2425 6C8.2425 4.755 7.245 3.75 6 3.75C4.755 3.75 3.75 4.755 3.75 6C3.75 7.245 4.755 8.25 6 8.25ZM6 9.75C4.2525 9.75 0.75 10.6275 0.75 12.375V14.25H11.25V12.375C11.25 10.6275 7.7475 9.75 6 9.75ZM12 9.75C11.7825 9.75 11.535 9.765 11.2725 9.7875C12.1425 10.4175 12.75 11.265 12.75 12.375V14.25H17.25V12.375C17.25 10.6275 13.7475 9.75 12 9.75Z\" fill=\"#1570EF\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});