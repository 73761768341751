define("ember-svg-jar/inlined/template-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.588 21.413C4.97933 21.8043 5.45 22 6 22H18C18.55 22 19.021 21.8043 19.413 21.413C19.8043 21.021 20 20.55 20 20V8.825C20 8.55833 19.95 8.304 19.85 8.062C19.75 7.82067 19.6083 7.60833 19.425 7.425L14.575 2.575C14.3917 2.39167 14.1793 2.25 13.938 2.15C13.696 2.05 13.4417 2 13.175 2H6C5.45 2 4.97933 2.19567 4.588 2.587C4.196 2.979 4 3.45 4 4V20C4 20.55 4.196 21.021 4.588 21.413ZM13 4V8C13 8.28333 13.096 8.52067 13.288 8.712C13.4793 8.904 13.7167 9 14 9H18V20H6V4H13ZM10.061 17.9251L11.9999 16.7021L13.9388 17.9251C14.0245 17.9822 14.114 18.0066 14.2075 17.9985C14.3009 17.9903 14.3827 17.9577 14.4527 17.9006C14.5228 17.8436 14.5773 17.7723 14.6163 17.6869C14.6552 17.6011 14.663 17.5052 14.6396 17.3992L14.1257 15.0877L15.8427 13.5345C15.9205 13.4611 15.9693 13.3775 15.9889 13.2835C16.0082 13.1899 16.0023 13.0983 15.9712 13.0086C15.94 12.9189 15.8933 12.8455 15.831 12.7885C15.7687 12.7314 15.6831 12.6947 15.574 12.6784L13.3081 12.4705L12.4321 10.2935C12.3931 10.1957 12.3329 10.1223 12.2513 10.0734C12.1694 10.0245 12.0856 10 11.9999 10C11.9143 10 11.8306 10.0245 11.749 10.0734C11.6671 10.1223 11.6067 10.1957 11.5677 10.2935L10.6917 12.4705L8.42579 12.6784C8.31677 12.6947 8.23112 12.7314 8.16882 12.7885C8.10653 12.8455 8.05981 12.9189 8.02866 13.0086C7.99752 13.0983 7.99175 13.1899 8.01138 13.2835C8.03069 13.3775 8.07928 13.4611 8.15714 13.5345L9.87413 15.0877L9.3602 17.3992C9.33684 17.5052 9.34463 17.6011 9.38356 17.6869C9.42249 17.7723 9.477 17.8436 9.54708 17.9006C9.61716 17.9577 9.69892 17.9903 9.79236 17.9985C9.88581 18.0066 9.97535 17.9822 10.061 17.9251Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});