define("ember-svg-jar/inlined/tune-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M2 11.3333V12.6667H6V11.3333H2ZM2 3.33333V4.66667H8.66667V3.33333H2ZM8.66667 14V12.6667H14V11.3333H8.66667V10H7.33333V14H8.66667ZM4.66667 6V7.33333H2V8.66667H4.66667V10H6V6H4.66667ZM14 8.66667V7.33333H7.33333V8.66667H14ZM10 6H11.3333V4.66667H14V3.33333H11.3333V2H10V6Z\" fill=\"#364152\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});