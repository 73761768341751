define("ember-svg-jar/inlined/list-alt-2-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 9H5V7H3V9ZM5 13H3V11H5V13ZM5 17H3V15H5V17ZM21 13H7V11H21V13ZM7 17H21V15H7V17ZM7 9V7H21V9H7Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});