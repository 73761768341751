define("ember-svg-jar/inlined/star-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.413 17.434l4.351 3.167a.703.703 0 0 0 1.082-.787l-1.665-5.104a.703.703 0 0 1 .26-.79l4.304-3.063a.703.703 0 0 0-.403-1.277H15.06a.703.703 0 0 1-.667-.486l-1.721-5.342a.703.703 0 0 0-1.339 0l-1.721 5.34a.703.703 0 0 1-.668.488H3.66a.703.703 0 0 0-.407 1.276l4.303 3.063a.703.703 0 0 1 .262.79l-1.663 5.105a.703.703 0 0 0 1.082.787l4.35-3.167a.703.703 0 0 1 .826 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});