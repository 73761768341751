define("ember-svg-jar/inlined/times-thick-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.572 12L21 18.428 18.428 21 12 14.572 5.572 21 3 18.428 9.428 12 3 5.572 5.572 3 12 9.428 18.428 3 21 5.572z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});