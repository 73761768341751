define("ember-svg-jar/inlined/reply-all-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.66667 3.33325V5.33325L2 7.99992L4.66667 10.6666V12.6666L0 7.99992L4.66667 3.33325ZM8.66667 3.33325V5.99992C13.3333 6.66659 15.3333 9.99992 16 13.3333C14.3333 10.9999 12 9.93325 8.66667 9.93325V12.6666L4 7.99992L8.66667 3.33325Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});