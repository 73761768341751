define("ember-svg-jar/inlined/link-off-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M12.75 5.25H9.75V6.675H12.75C14.0325 6.675 15.075 7.7175 15.075 9C15.075 10.0725 14.34 10.9725 13.3425 11.235L14.4375 12.33C15.66 11.7075 16.5 10.4625 16.5 9C16.5 6.93 14.82 5.25 12.75 5.25ZM12 8.25H10.3575L11.8575 9.75H12V8.25ZM1.5 3.2025L3.8325 5.535C2.4675 6.09 1.5 7.4325 1.5 9C1.5 11.07 3.18 12.75 5.25 12.75H8.25V11.325H5.25C3.9675 11.325 2.925 10.2825 2.925 9C2.925 7.8075 3.8325 6.825 4.995 6.6975L6.5475 8.25H6V9.75H8.0475L9.75 11.4525V12.75H11.0475L14.055 15.75L15 14.805L2.4525 2.25L1.5 3.2025Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});