define("ember-svg-jar/inlined/user-tag-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.865 13.327a3.752 3.752 0 0 0-.722-.555 3.655 3.655 0 0 0-1.883-.512c-.672 0-1.309.173-1.883.512a3.754 3.754 0 0 0-1.35 1.37 3.727 3.727 0 0 0-.504 1.881c0 .672.173 1.31.513 1.883.1.168.21.326.33.474a6.533 6.533 0 0 0-1.608.764c-.85.563-1.398 1.314-1.486 2.223l-.181.17a1.591 1.591 0 0 1-2.243 0L2.834 14.87c-.623-.62-1.184-2.18-.566-2.798l9.624-8.686c.462-.461 5.432-.403 7.777-.349a1.3 1.3 0 0 1 1.252 1.243c.055 1.832.134 5.286.022 7.09-.041.652-.597 1.493-1.078 1.958zm-3.36-7.637a1.334 1.334 0 0 0 0 1.89 1.343 1.343 0 0 0 1.897 0 1.335 1.335 0 0 0 0-1.89 1.344 1.344 0 0 0-1.896 0zm.755 13.702c.557 0 1.168.08 1.832.242.755.189 1.366.44 1.833.755.557.368.835.781.835 1.24v1.131h-9v-1.132c0-.458.278-.871.835-1.24.467-.314 1.078-.565 1.833-.754a7.799 7.799 0 0 1 1.832-.242zm0-1.132c-.404 0-.777-.101-1.118-.303a2.287 2.287 0 0 1-.815-.815 2.155 2.155 0 0 1-.304-1.119c0-.404.102-.781.304-1.131s.473-.627.815-.829a2.155 2.155 0 0 1 1.118-.303c.404 0 .777.101 1.118.303.342.202.613.478.815.829.202.35.304.727.304 1.131 0 .405-.102.777-.304 1.119a2.287 2.287 0 0 1-.815.815 2.155 2.155 0 0 1-1.118.303z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});