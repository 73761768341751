define("ember-svg-jar/inlined/sticky-note-add-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.75 3.75V12.1125L12.1125 15.75H3.75C2.92157 15.75 2.25 15.0784 2.25 14.25V3.75C2.25 2.92157 2.92157 2.25 3.75 2.25H14.25C15.0784 2.25 15.75 2.92157 15.75 3.75ZM11.25 11.25V14.625L14.625 11.25H11.25ZM6 8.625C6 8.41789 6.16789 8.25 6.375 8.25H8.25V6.375C8.25 6.16789 8.41789 6 8.625 6H9.375C9.58211 6 9.75 6.16789 9.75 6.375V8.25H11.625C11.8321 8.25 12 8.41789 12 8.625V9.375C12 9.58211 11.8321 9.75 11.625 9.75H9.75V11.625C9.75 11.8321 9.58211 12 9.375 12H8.625C8.41789 12 8.25 11.8321 8.25 11.625V9.75H6.375C6.16789 9.75 6 9.58211 6 9.375V8.625Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});