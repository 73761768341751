define("ember-svg-jar/inlined/square-rounded-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M8.44444 4H15.5556C18.0089 4 20 5.99111 20 8.44444V15.5556C20 18.0089 18.0089 20 15.5556 20H8.44444C5.99111 20 4 18.0089 4 15.5556V8.44444C4 5.99111 5.99111 4 8.44444 4Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});