define("ember-svg-jar/inlined/catalyst-radio-circle-disabled-off", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect x=\"0.5\" y=\"0.5\" width=\"19\" height=\"19\" rx=\"9.5\" fill=\"#EBEFF2\"/>\n<rect x=\"0.5\" y=\"0.5\" width=\"19\" height=\"19\" rx=\"9.5\" stroke=\"#CDD5DF\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});