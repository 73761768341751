define("ember-svg-jar/inlined/ach-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M5.25 7.875H3V13.125H5.25V7.875Z\" fill=\"#4B5565\"/>\n<path d=\"M10.125 7.875H7.875V13.125H10.125V7.875Z\" fill=\"#4B5565\"/>\n<path d=\"M16.5 14.625H1.5V16.875H16.5V14.625Z\" fill=\"#4B5565\"/>\n<path d=\"M15 7.875H12.75V13.125H15V7.875Z\" fill=\"#4B5565\"/>\n<path d=\"M9 1.125L1.5 4.875V6.375H16.5V4.875L9 1.125Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});