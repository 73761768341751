define("ember-svg-jar/inlined/receipt-dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 20.5L3 22V2.00002L4.5 3.50002L6 2.00002L7.5 3.50002L9 2.00002L10.5 3.50002L12 2.00002L13.5 3.50002L15 2.00002L16.5 3.50002L18 2.00002L19.5 3.50002L21 2.00002V22L19.5 20.5L18 22L16.5 20.5L15 22L13.5 20.5L12 22L10.5 20.5L9 22L7.5 20.5L6 22L4.5 20.5ZM13.0575 18V16.5675C14.3625 16.32 15.39 15.57 15.3975 14.1975C15.3975 12.3075 13.7775 11.655 12.2625 11.265C10.7475 10.875 10.26 10.4625 10.26 9.83252C10.26 9.11252 10.935 8.60252 12.06 8.60252C13.245 8.60252 13.6875 9.17252 13.725 10.005H15.195C15.15 8.85752 14.4525 7.80002 13.0575 7.46252V6.00002H11.055V7.44752C9.76498 7.72502 8.72248 8.56502 8.72248 9.85502C8.72248 11.3925 9.99748 12.1575 11.8575 12.6075C13.53 13.005 13.86 13.59 13.86 14.2125C13.86 14.67 13.53 15.405 12.06 15.405C10.6875 15.405 10.1475 14.79 10.0725 14.0025H8.60248C8.68498 15.4575 9.77248 16.2825 11.055 16.5525V18H13.0575Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});