define("ember-svg-jar/inlined/link-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M11.333 4.66602H8.66634V5.99935H11.333C12.433 5.99935 13.333 6.89935 13.333 7.99935C13.333 9.09935 12.433 9.99935 11.333 9.99935H8.66634V11.3327H11.333C13.173 11.3327 14.6663 9.83935 14.6663 7.99935C14.6663 6.15935 13.173 4.66602 11.333 4.66602Z\"/>\n<path d=\"M7.33301 9.99935H4.66634C3.56634 9.99935 2.66634 9.09935 2.66634 7.99935C2.66634 6.89935 3.56634 5.99935 4.66634 5.99935H7.33301V4.66602H4.66634C2.82634 4.66602 1.33301 6.15935 1.33301 7.99935C1.33301 9.83935 2.82634 11.3327 4.66634 11.3327H7.33301V9.99935Z\"/>\n<path d=\"M5.33301 7.33268H10.6663V8.66602H5.33301V7.33268Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});