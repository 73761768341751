define("ember-svg-jar/inlined/important-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 19.7778H15.3333C16.0556 19.7778 16.7333 19.4222 17.1444 18.8444L22 12L17.1444 5.15556C16.7333 4.56667 16.0556 4.22223 15.3333 4.22223H2L7 12L2 19.7778ZM15.3333 17.5556H5.88889L9.77778 12L5.88889 6.44445H15.3333L19.2222 12L15.3333 17.5556Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});