define("ember-svg-jar/inlined/avatar-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"44\" cy=\"44\" r=\"44\" fill=\"#84BFF8\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M54.6665 33.3332H50.4398L47.9998 30.6665H39.9998L37.5598 33.3332H33.3332C31.8665 33.3332 30.6665 34.5332 30.6665 35.9998V51.9998C30.6665 53.4665 31.8665 54.6665 33.3332 54.6665H54.6665C56.1332 54.6665 57.3332 53.4665 57.3332 51.9998V35.9998C57.3332 34.5332 56.1332 33.3332 54.6665 33.3332ZM43.9998 47.9998C46.209 47.9998 47.9998 46.209 47.9998 43.9998C47.9998 41.7907 46.209 39.9998 43.9998 39.9998C41.7907 39.9998 39.9998 41.7907 39.9998 43.9998C39.9998 46.209 41.7907 47.9998 43.9998 47.9998ZM37.3332 43.9998C37.3332 47.6798 40.3198 50.6665 43.9998 50.6665C47.6798 50.6665 50.6665 47.6798 50.6665 43.9998C50.6665 40.3198 47.6798 37.3332 43.9998 37.3332C40.3198 37.3332 37.3332 40.3198 37.3332 43.9998Z\" fill=\"white\"/>",
    "attrs": {
      "viewBox": "0 0 88 88",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});