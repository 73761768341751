define("ember-svg-jar/inlined/user-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12 14.012c.99 0 2.076.144 3.257.431 1.342.335 2.428.783 3.258 1.341.99.655 1.485 1.39 1.485 2.204V20H4v-2.012c0-.814.495-1.549 1.485-2.204.83-.558 1.916-1.006 3.258-1.34 1.181-.288 2.267-.432 3.257-.432zM12 12c-.719 0-1.381-.18-1.988-.539a4.065 4.065 0 0 1-1.45-1.449 3.832 3.832 0 0 1-.538-1.988c0-.719.18-1.39.539-2.012a4.008 4.008 0 0 1 1.449-1.473A3.832 3.832 0 0 1 12 4c.719 0 1.381.18 1.988.539.607.36 1.09.85 1.45 1.473a3.97 3.97 0 0 1 .538 2.012c0 .719-.18 1.381-.539 1.988a4.065 4.065 0 0 1-1.449 1.45A3.845 3.845 0 0 1 12 12z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});