define("ember-svg-jar/inlined/comment-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"nonzero\" d=\"M18.4 4H5.6c-.884 0-1.592.716-1.592 1.6L4 20l3.2-3.2h11.2a1.6 1.6 0 0 0 1.6-1.6V5.6A1.6 1.6 0 0 0 18.4 4zm-8.8 7.2H8V9.6h1.6v1.6zm3.2 0h-1.6V9.6h1.6v1.6zm3.2 0h-1.6V9.6H16v1.6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});