define("ember-svg-jar/inlined/idendity-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.3335 6.66659C13.3335 8.50825 11.8418 9.99992 10.0002 9.99992C8.1585 9.99992 6.66683 8.50825 6.66683 6.66659C6.66683 4.82492 8.1585 3.33325 10.0002 3.33325C11.8418 3.33325 13.3335 4.82492 13.3335 6.66659ZM11.6668 6.66658C11.6668 5.74992 10.9168 4.99992 10.0002 4.99992C9.0835 4.99992 8.3335 5.74992 8.3335 6.66658C8.3335 7.58325 9.0835 8.33325 10.0002 8.33325C10.9168 8.33325 11.6668 7.58325 11.6668 6.66658ZM10.0002 10.8333C7.77516 10.8333 3.3335 11.9499 3.3335 14.1666V16.6666H16.6668V14.1666C16.6668 11.9499 12.2252 10.8333 10.0002 10.8333ZM5.00016 14.1749V14.9999H15.0002V14.1666C14.8335 13.5749 12.2502 12.4999 10.0002 12.4999C7.75016 12.4999 5.16683 13.5749 5.00016 14.1749Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});