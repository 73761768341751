define("ember-svg-jar/inlined/slow-play-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.66634 1.70898V3.39232C8.44968 3.54232 7.34134 4.02565 6.41634 4.74232L5.23301 3.55065C6.46634 2.54232 7.99134 1.87565 9.66634 1.70898ZM11.3747 8.15898L8.83301 6.25065V13.7507L11.3747 11.8423L13.833 10.0007L11.3747 8.15898ZM5.24134 5.91732L4.04967 4.73398C3.04134 5.96732 2.37467 7.49232 2.20801 9.16732H3.89134C4.04134 7.95065 4.52467 6.84232 5.24134 5.91732ZM2.20801 10.834H3.89134C4.04134 12.0507 4.52467 13.159 5.24134 14.0757L4.04967 15.2673C3.04134 14.034 2.37467 12.509 2.20801 10.834ZM5.23301 16.4507C6.46634 17.459 7.99967 18.1257 9.66634 18.2923V16.609C8.44968 16.459 7.34134 15.9757 6.41634 15.259L5.23301 16.4507ZM11.3747 18.2923C15.5663 17.8507 18.833 14.3007 18.833 10.0007C18.833 5.70065 15.5663 2.15065 11.3747 1.70898V3.39232C14.6413 3.82565 17.1663 6.62565 17.1663 10.0007C17.1663 13.3757 14.6413 16.1756 11.3747 16.609V18.2923Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});