define("ember-svg-jar/inlined/apps-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 7H7V2H2V7ZM9.5 22H14.5V17H9.5V22ZM7 22H2V17H7V22ZM2 14.5H7V9.5H2V14.5ZM14.5 14.5H9.5V9.5H14.5V14.5ZM17 2V7H22V2H17ZM14.5 7H9.5V2H14.5V7ZM17 14.5H22V9.5H17V14.5ZM22 22H17V17H22V22Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});