define("ember-svg-jar/inlined/notes-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 4H14V5.33333L2 5.34V4ZM14 7.34L2 7.33333V8.66667H14V7.34ZM2 10.6667H10V12H2V10.6667Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});