define("ember-svg-jar/inlined/dollar-symbol-o-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n    <path fill-rule=\"nonzero\" d=\"M12 19.72a7.72 7.72 0 1 1 0-15.44 7.72 7.72 0 0 1 0 15.44zm1.21-8.38c1.04.646 1.32 2.112.586 3.115a1.933 1.933 0 0 1-1.012.728c-.175.054-.254.159-.245.363.007.2-.002.403-.002.603 0 .18-.08.276-.239.28-.192.006-.386.006-.578.002-.17-.004-.25-.112-.251-.297-.002-.147-.002-.295-.003-.442-.004-.323-.012-.337-.29-.387-.356-.064-.705-.154-1.03-.332-.257-.14-.284-.212-.21-.512.055-.224.11-.448.171-.67.072-.256.134-.288.346-.165.36.21.743.328 1.143.384.26.036.512.007.75-.11.447-.22.516-.8.14-1.15a1.706 1.706 0 0 0-.426-.28c-.392-.195-.8-.342-1.17-.59-.599-.403-.98-.957-.935-1.776.05-.925.516-1.503 1.272-1.812.312-.127.314-.121.316-.488.002-.125-.002-.248.002-.372.006-.275.048-.323.291-.331.076-.002.152 0 .227 0 .522 0 .522 0 .524.584.001.414.001.416.369.48.282.05.55.143.81.272.143.071.198.183.153.352-.067.253-.127.507-.199.758-.069.237-.136.272-.338.162a2.353 2.353 0 0 0-1.285-.285.888.888 0 0 0-.341.079c-.388.19-.452.672-.12.97.168.15.358.258.558.35.343.16.69.314 1.016.517zM12 18.28a6.28 6.28 0 1 0 0-12.56 6.28 6.28 0 0 0 0 12.56z\"/>\n",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});