define("ember-svg-jar/inlined/briefcase-tag-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"nonzero\" d=\"M10.5 21.91a1.594 1.594 0 0 1-1.652-.373L2.834 14.87c-.623-.62-1.184-2.18-.566-2.798l9.624-8.686c.462-.461 5.432-.403 7.777-.349a1.3 1.3 0 0 1 1.252 1.243c.055 1.832.134 5.286.022 7.09-.04.631-.562 1.44-1.032 1.912a2.325 2.325 0 0 0-1.74-.781h-2.343c-1.186 0-2.13.88-2.337 2h-.958a2.03 2.03 0 0 0-2.033 2.026v5.384zm6.006-16.22a1.334 1.334 0 0 0 0 1.89 1.343 1.343 0 0 0 1.896 0 1.335 1.335 0 0 0 0-1.89 1.344 1.344 0 0 0-1.896 0zM18.172 16v-.86c0-.177-.131-.32-.293-.32H16.12c-.162 0-.293.143-.293.32V16h2.344zm-3.223 0v-1.04c0-.53.395-.96.88-.96h2.343c.484 0 .879.43.879.96V16h2.416a.53.53 0 0 1 .533.526v5.948a.53.53 0 0 1-.533.526h-8.934a.53.53 0 0 1-.533-.526v-5.948a.53.53 0 0 1 .533-.526h2.416z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});