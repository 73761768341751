define("ember-svg-jar/inlined/purchase-order-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M17.275 20.25L20.75 16.8L19.7 15.75L17.275 18.125L16.3 17.15L15.25 18.225L17.275 20.25ZM6 9H18V7H6V9ZM18 23C16.6167 23 15.4377 22.5123 14.463 21.537C13.4883 20.5617 13.0007 19.3827 13 18C12.9993 16.6173 13.487 15.4383 14.463 14.463C15.439 13.4877 16.618 13 18 13C19.382 13 20.5613 13.4877 21.538 14.463C22.5147 15.4383 23.002 16.6173 23 18C22.998 19.3827 22.5103 20.562 21.537 21.538C20.5637 22.514 19.3847 23.0013 18 23ZM3 22V5C3 4.45 3.196 3.97933 3.588 3.588C3.98 3.19667 4.45067 3.00067 5 3H19C19.55 3 20.021 3.196 20.413 3.588C20.805 3.98 21.0007 4.45067 21 5V11.675C20.5333 11.4417 20.0457 11.2707 19.537 11.162C19.0283 11.0533 18.516 10.9993 18 11H6V13H13.1C12.8167 13.2833 12.5543 13.5917 12.313 13.925C12.0717 14.2583 11.859 14.6167 11.675 15H6V17H11.075C11.0417 17.1667 11.021 17.3293 11.013 17.488C11.005 17.6467 11.0007 17.8173 11 18C11 18.7 11.096 19.371 11.288 20.013C11.48 20.655 11.7673 21.2673 12.15 21.85L12 22L10.5 20.5L9 22L7.5 20.5L6 22L4.5 20.5L3 22Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});