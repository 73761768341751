define("ember-svg-jar/inlined/minimize-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g><path d=\"M2.121 9a1 1 0 1 0 0 2h1.465L.293 14.293a.999.999 0 1 0 1.414 1.414l3.414-3.414V14c0 .552.447 1 1 1S7 14.552 7 14V9H2.121zM3 7a1 1 0 0 0 1-1V4h2a1 1 0 1 0 0-2H2.001L2 6a1 1 0 0 0 1 1zM13 9a1 1 0 0 0-1 1v2h-2a1 1 0 1 0 0 2h4v-4a1 1 0 0 0-1-1zM14.293.293L11 3.586V2a1 1 0 1 0-2 0v5h5a1 1 0 0 0 0-2h-1.586l3.293-3.292c.391-.391.391-1.023 0-1.414s-1.023-.392-1.414-.001z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 16"
    }
  };
});