define("ember-svg-jar/inlined/checkmark-circle-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"nonzero\" d=\"M17.331 10.16a.6.6 0 0 0-.176-.445l-.89-.872a.605.605 0 0 0-.44-.184.605.605 0 0 0-.441.184l-3.992 3.942-2.211-2.189a.605.605 0 0 0-.44-.184.605.605 0 0 0-.44.184l-.891.872a.6.6 0 0 0-.176.446c0 .174.058.32.176.436l3.542 3.506c.124.123.27.184.44.184a.616.616 0 0 0 .45-.184l5.313-5.26a.588.588 0 0 0 .176-.436zm3.152-2.912c.866 1.479 1.299 3.097 1.299 4.853 0 1.756-.433 3.376-1.299 4.86a9.637 9.637 0 0 1-3.523 3.522c-1.483.866-3.103 1.299-4.86 1.299-1.755 0-3.375-.433-4.858-1.299a9.637 9.637 0 0 1-3.524-3.523c-.865-1.483-1.298-3.103-1.298-4.86 0-1.755.433-3.375 1.298-4.858a9.637 9.637 0 0 1 3.524-3.524C8.725 2.853 10.345 2.42 12.1 2.42c1.756 0 3.376.433 4.86 1.298a9.623 9.623 0 0 1 3.522 3.53z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 24 24"
    }
  };
});