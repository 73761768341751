define("ember-svg-jar/inlined/paperclip-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.5711 10.5858L11.4393 18.7175C9.87664 20.2802 7.34519 20.2802 5.78249 18.7175C4.21978 17.1548 4.21978 14.6234 5.78249 13.0607L14.6213 4.22183C15.5971 3.24602 17.181 3.24602 18.1569 4.22183C19.1327 5.19763 19.1327 6.78155 18.1569 7.75736L10.7322 15.182C10.3433 15.5709 9.70693 15.5709 9.31802 15.182C8.92911 14.7931 8.92911 14.1567 9.31802 13.7678L16.0355 7.05025L14.9749 5.98959L8.25736 12.7071C7.28155 13.6829 7.28155 15.2668 8.25736 16.2426C9.23317 17.2184 10.8171 17.2184 11.7929 16.2426L19.2175 8.81802C20.7802 7.25531 20.7802 4.72387 19.2175 3.16117C17.6548 1.59846 15.1234 1.59846 13.5607 3.16117L4.72183 12C2.57222 14.1496 2.57222 17.6286 4.72183 19.7782C6.87143 21.9278 10.3504 21.9278 12.5 19.7782L20.6317 11.6464L19.5711 10.5858Z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});