define("ember-svg-jar/inlined/bell-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16.5 11.375c0-2.306-1.226-4.23-3.375-4.74v-.51C13.125 5.503 12.623 5 12 5s-1.125.503-1.125 1.125v.51c-2.149.51-3.375 2.434-3.375 4.74v3.75l-1.5 1.5v.75h12v-.75l-1.5-1.5zm-4.196 8.22a1.515 1.515 0 0 0 1.08-.885c.075-.18.116-.375.116-.585h-3a1.5 1.5 0 0 0 1.804 1.47z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});