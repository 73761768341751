define("ember-svg-jar/inlined/arrow-circle-right-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.106 4.636a.44.44 0 0 1 .062-.62l1.199-.982-1.2-.982a.44.44 0 1 1 .559-.682l1.616 1.323a.44.44 0 0 1 0 .682L2.726 4.698a.44.44 0 0 1-.62-.062m.928 1.433A3.038 3.038 0 0 0 6.07 3.034 3.038 3.038 0 0 0 3.034 0 3.038 3.038 0 0 0 0 3.034 3.038 3.038 0 0 0 3.034 6.07\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 6.069 6.069"
    }
  };
});