define("ember-svg-jar/inlined/cloud-upload-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M25.8 13.3867C24.8933 8.78668 20.8533 5.33334 16 5.33334C12.1467 5.33334 8.8 7.52001 7.13333 10.72C3.12 11.1467 0 14.5467 0 18.6667C0 23.08 3.58667 26.6667 8 26.6667H25.3333C29.0133 26.6667 32 23.68 32 20C32 16.48 29.2667 13.6267 25.8 13.3867ZM25.3333 24H8C5.05333 24 2.66667 21.6133 2.66667 18.6667C2.66667 15.9333 4.70667 13.6533 7.41333 13.3733L8.84 13.2267L9.50667 11.96C10.7733 9.52001 13.2533 8.00001 16 8.00001C19.4933 8.00001 22.5067 10.48 23.1867 13.9067L23.5867 15.9067L25.6267 16.0533C27.7067 16.1867 29.3333 17.9333 29.3333 20C29.3333 22.2 27.5333 24 25.3333 24ZM10.6667 17.3333H14.0667V21.3333H17.9333V17.3333H21.3333L16 12L10.6667 17.3333Z\" fill=\"#CDD5DF\"/>\n",
    "attrs": {
      "viewBox": "0 0 32 32",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});