define("ember-svg-jar/inlined/my-location-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.96 6.833A5.996 5.996 0 0 0 8.666 1.54V.167H7.333V1.54A5.996 5.996 0 0 0 2.04 6.833H.667v1.334H2.04a5.996 5.996 0 0 0 5.293 5.293v1.373h1.333V13.46a5.996 5.996 0 0 0 5.294-5.293h1.373V6.833H13.96zm-5.96-2a2.666 2.666 0 1 0 0 5.334 2.666 2.666 0 1 0 0-5.334zM3.333 7.5A4.663 4.663 0 0 0 8 12.167 4.663 4.663 0 0 0 12.666 7.5 4.663 4.663 0 0 0 8 2.833 4.663 4.663 0 0 0 3.333 7.5z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});