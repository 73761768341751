define("ember-svg-jar/inlined/table-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.346 3.783H1.369c-.274 0-.496.178-.496.397 0 .22.222.397.496.397h2.977c.274 0 .496-.177.496-.397 0-.219-.222-.397-.496-.397zm0-1.322H1.369c-.274 0-.496.177-.496.396 0 .22.222.397.496.397h2.977c.274 0 .496-.177.496-.397 0-.219-.222-.396-.496-.396zm-2.977-.53h2.977c.274 0 .496-.177.496-.396 0-.22-.222-.397-.496-.397H1.369c-.274 0-.496.177-.496.397 0 .219.222.396.496.396z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 5.715 5.715"
    }
  };
});