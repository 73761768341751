define("ember-cropperjs/templates/components/image-cropper-call", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  
  */
  {
    "id": "B9TbSMdL",
    "block": "[[[18,1,null],[1,\"\\n\"]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-cropperjs/templates/components/image-cropper-call.hbs",
    "isStrictMode": false
  });
});