define("ember-svg-jar/inlined/users-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.99337 5.33325C7.99337 6.43992 7.10671 7.33325 6.00004 7.33325C4.89337 7.33325 4.00004 6.43992 4.00004 5.33325C4.00004 4.22659 4.89337 3.33325 6.00004 3.33325C7.10671 3.33325 7.99337 4.22659 7.99337 5.33325ZM12.66 6.33325C12.66 7.25325 11.92 7.99992 11 7.99992C10.08 7.99992 9.33337 7.25325 9.33337 6.33325C9.33337 5.41325 10.08 4.66659 11 4.66659C11.92 4.66659 12.66 5.41325 12.66 6.33325ZM11 9.33325C9.78004 9.33325 7.33337 9.94659 7.33337 11.1666V12.6666H14.6667V11.1666C14.6667 9.94659 12.22 9.33325 11 9.33325ZM1.33337 10.9999C1.33337 9.44659 4.44671 8.66659 6.00004 8.66659C6.44004 8.66659 7.00004 8.73325 7.58004 8.85325C6.22004 9.60659 6.00004 10.5999 6.00004 11.1666V12.6666H1.33337V10.9999Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});