define("ember-svg-jar/inlined/filter-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M14.9995 19.8777C15.0395 20.1776 14.9395 20.4976 14.7096 20.7075C14.3196 21.0975 13.6897 21.0975 13.2998 20.7075L9.29045 16.6981C9.06049 16.4681 8.9605 16.1582 9.0005 15.8682V10.7489L4.21129 4.61978C3.87134 4.18983 3.95133 3.55992 4.38126 3.21997C4.57123 3.07999 4.78119 3 5.00116 3H18.9988C19.2188 3 19.4288 3.07999 19.6187 3.21997C20.0487 3.55992 20.1287 4.18983 19.7887 4.61978L14.9995 10.7489V19.8777ZM7.04082 4.99972L11.0002 10.059V15.5783L12.9998 17.578V10.049L16.9592 4.99972H7.04082Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});