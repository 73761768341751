define("ember-svg-jar/inlined/reply-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M6.66667 6.00001V3.33334L2 8.00001L6.66667 12.6667V9.93334C10 9.93334 12.3333 11 14 13.3333C13.3333 10 11.3333 6.66668 6.66667 6.00001Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});