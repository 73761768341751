define("ember-svg-jar/inlined/edit-alt-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M15.9 15.075H2.1C1.76812 15.075 1.5 15.3431 1.5 15.675V16.35C1.5 16.4325 1.5675 16.5 1.65 16.5H16.35C16.4325 16.5 16.5 16.4325 16.5 16.35V15.675C16.5 15.3431 16.2319 15.075 15.9 15.075ZM4.23188 13.5C4.26938 13.5 4.30688 13.4963 4.34438 13.4906L7.49813 12.9375C7.53562 12.93 7.57125 12.9131 7.5975 12.885L15.5456 4.93687C15.563 4.91953 15.5768 4.89892 15.5862 4.87624C15.5956 4.85356 15.6005 4.82924 15.6005 4.80469C15.6005 4.78013 15.5956 4.75582 15.5862 4.73313C15.5768 4.71045 15.563 4.68985 15.5456 4.6725L12.4294 1.55438C12.3938 1.51875 12.3469 1.5 12.2963 1.5C12.2456 1.5 12.1988 1.51875 12.1631 1.55438L4.215 9.5025C4.18687 9.53063 4.17 9.56437 4.1625 9.60188L3.60938 12.7556C3.59113 12.8561 3.59765 12.9594 3.62836 13.0568C3.65907 13.1542 3.71305 13.2426 3.78562 13.3144C3.90937 13.4344 4.065 13.5 4.23188 13.5Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});