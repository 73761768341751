define("ember-svg-jar/inlined/icon-progress-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M8.6667 1.35327C11.82 1.6666 14.3334 4.1666 14.6334 7.33327C15 10.9999 12.3334 14.2533 8.6667 14.6199V13.2866C11.0934 12.9999 13 11.0733 13.3067 8.64661C13.6667 5.71994 11.5934 3.05994 8.6667 2.69994V1.3666V1.35327ZM7.33337 1.37327V2.7066C6.38003 2.83994 5.48003 3.2266 4.73337 3.8266L3.78003 2.83994C4.79337 1.99994 6.03337 1.49994 7.33337 1.37327ZM2.84003 3.77994L3.79337 4.73327C3.20003 5.4866 2.8267 6.38661 2.70003 7.33327H1.3667C1.50003 6.02661 2.00003 4.79327 2.84003 3.77994ZM1.37337 8.6666H2.7067C2.8267 9.61327 3.2067 10.5133 3.79337 11.2666L2.8467 12.2199C2.02003 11.2066 1.5067 9.97327 1.37337 8.6666ZM4.73337 12.2466C5.4867 12.8333 6.3867 13.2133 7.33337 13.3333V14.6666C6.0267 14.5266 4.7867 13.9999 3.78003 13.1599L4.73337 12.2466ZM8.00003 4.99994L5.00003 7.99994H7.33337V10.6666H8.6667V7.99994H11L8.00003 4.99994Z\" fill=\"#0B80F3\"/>\n",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});