define("ember-svg-jar/inlined/priority-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M4.5 2.25V3.75M4.5 3.75H13.5L11.4376 6.75L13.5 9.75H4.5M4.5 3.75V9.75M4.5 15.75V9.75\" stroke=\"#4B5565\" stroke-width=\"2\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});