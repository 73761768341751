define("ember-svg-jar/inlined/collapse-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16 5H13V1H11V5H8L12 9L16 5ZM8 19H11V23H13V19H16L12 15L8 19ZM4 13V11H20V13H4Z\" fill=\"#7C849B\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});