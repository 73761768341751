define("ember-svg-jar/inlined/add-mention-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M16.7368 20.1263H4.10526V7.26316H11.4737V5.1579H4.10526C2.94737 5.1579 2 6.10527 2 7.26316V19.8947C2 21.0526 2.94737 22 4.10526 22H16.7368C17.8947 22 18.8421 21.0526 18.8421 19.8947V12.5263H16.7368V20.1263Z\" fill=\"currentColor\"/>\n<path d=\"M18.8421 2H16.7368V5.15789H13.5789C13.5894 5.16842 13.5789 7.26316 13.5789 7.26316H16.7368V10.4105C16.7473 10.4211 18.8421 10.4105 18.8421 10.4105V7.26316H22V5.15789H18.8421V2Z\" fill=\"currentColor\"/>\n<path d=\"M14.6316 9.36841H6.21051V11.4737H14.6316V9.36841Z\" fill=\"currentColor\"/>\n<path d=\"M6.21051 12.5263V14.6316H14.6316V12.5263H11.4737H6.21051Z\" fill=\"currentColor\"/>\n<path d=\"M14.6316 15.6842H6.21051V17.7895H14.6316V15.6842Z\" fill=\"currentColor\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});