define("ember-svg-jar/inlined/view-condensed-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 5.75V3.25H22V5.75H2ZM2 10.75H22V8.25H2V10.75ZM22 15.75H2V13.25H22V15.75ZM22 20.75H2V18.25H22V20.75Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});