define("ember-svg-jar/inlined/pin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.783 10.8217L10.8217 5.86047L11.4419 5.24031L10.2016 4L4 10.2016L5.24031 11.4419L5.86047 10.8217L10.8217 15.783V18.2636L12.062 19.5039L15.2869 16.2791L19.0078 20L20 19.0078L16.2791 15.2869L19.5039 12.062L18.2636 10.8217H15.783Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});