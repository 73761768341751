define("ember-svg-jar/inlined/unpin-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M1.5 3.9525L2.46 3L15 15.54L14.0475 16.5L9.6 12.0525V16.5H8.4V12H4.5V10.5L6 9V8.4525L1.5 3.9525ZM12 9L13.5 10.5V12H13.365L6 4.635V3H5.25V1.5H12.75V3H12V9Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});