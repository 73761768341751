define("ember-svg-jar/inlined/folder-special-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.00065 4.00033H13.334C14.0673 4.00033 14.6673 4.60033 14.6673 5.33366V12.0003C14.6673 12.7337 14.0673 13.3337 13.334 13.3337H2.66732C1.93398 13.3337 1.33398 12.7337 1.33398 12.0003V4.00033C1.33398 3.26699 1.93398 2.66699 2.66732 2.66699H6.66732L8.00065 4.00033ZM10.0007 10.187L11.9607 11.3337L11.4407 9.11366L13.1673 7.62033L10.894 7.42699L10.0007 5.33366L9.10732 7.42699L6.83398 7.62033L8.56065 9.11366L8.04065 11.3337L10.0007 10.187Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});