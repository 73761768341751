define("ember-svg-jar/inlined/pdf-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20 2H8C6.9 2 6 2.9 6 4V16C6 17.1 6.9 18 8 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM11.5 9.5C11.5 10.33 10.83 11 10 11H9V13H7.5V7H10C10.83 7 11.5 7.67 11.5 8.5V9.5ZM15 13C15.83 13 16.5 12.33 16.5 11.5V8.5C16.5 7.67 15.83 7 15 7H12.5V13H15ZM20.5 8.5H19V9.5H20.5V11H19V13H17.5V7H20.5V8.5ZM10 9.5H9V8.5H10V9.5ZM4 6H2V20C2 21.1 2.9 22 4 22H18V20H4V6ZM15 11.5H14V8.5H15V11.5Z\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});