define("ember-svg-jar/inlined/catalyst-checkbox-square-disabled-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect width=\"20\" height=\"20\" rx=\"2\" fill=\"#CDD5DF\"/>\n<path d=\"M15.3334 6L8.00002 13.3333L4.66669 10\" stroke=\"white\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>\n",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});