define("ember-svg-jar/inlined/clear-outlined-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M19.5 6.01071L17.9893 4.5L12 10.4893L6.01071 4.5L4.5 6.01071L10.4893 12L4.5 17.9893L6.01071 19.5L12 13.5107L17.9893 19.5L19.5 17.9893L13.5107 12L19.5 6.01071Z\" fill=\"black\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});