define("ember-svg-jar/inlined/price-tag-remove-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.8 8C6.48174 8 6.17652 7.87357 5.95147 7.64853C5.72643 7.42348 5.6 7.11826 5.6 6.8C5.6 6.48174 5.72643 6.17652 5.95147 5.95147C6.17652 5.72643 6.48174 5.6 6.8 5.6C7.11826 5.6 7.42348 5.72643 7.64853 5.95147C7.87357 6.17652 8 6.48174 8 6.8C8 7.11826 7.87357 7.42348 7.64853 7.64853C7.42348 7.87357 7.11826 8 6.8 8ZM6.14214 14H19.456L13.928 19.528C13.64 19.816 13.24 20 12.8 20C12.36 20 11.96 19.816 11.664 19.528L6.14214 14ZM17.864 10L12.328 4.464C12.04 4.176 11.64 4 11.2 4H5.6C4.712 4 4 4.712 4 5.6V10H17.864ZM3 13C2.44772 13 2 12.5523 2 12C2 11.4477 2.44772 11 3 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H3Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});