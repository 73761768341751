define("ember-svg-jar/inlined/timetable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5 3C3.89543 3 3 3.89545 3 5V19C3 20.1046 3.89543 21 5 21H11.2547C10.8334 20.396 10.5049 19.7224 10.2899 19H5V16H10.0709C10.1719 15.2939 10.3783 14.6217 10.6736 14H5V11H11V13.3924C12.2249 11.3596 14.4537 10 17 10C18.4872 10 19.8662 10.4638 21 11.2547V5C21 3.89545 20.1046 3 19 3H5ZM13 6V9H19V6H13ZM5 9V6H11V9H5Z\" fill=\"#4B5565\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 11C13.7 11 11 13.7 11 17C11 20.3 13.7 23 17 23C20.3 23 23 20.3 23 17C23 13.7 20.3 11 17 11ZM17.7 14.9998C17.7 14.6132 17.3866 14.2998 17 14.2998C16.6134 14.2998 16.3 14.6132 16.3 14.9998V17.9737L19.2401 19.1497C19.599 19.2933 20.0064 19.1187 20.15 18.7598C20.2936 18.4008 20.119 17.9935 19.76 17.8499L17.7 17.0259V14.9998Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});