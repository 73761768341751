define("ember-svg-jar/inlined/attach-file-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M19.9104 10.862L11.5569 19.2446C9.95157 20.8556 7.35109 20.8556 5.74576 19.2446C4.14044 17.6337 4.14044 15.0241 5.74576 13.4132L14.8257 4.30159C15.8281 3.29567 17.4552 3.29567 18.4576 4.30159C19.46 5.30752 19.46 6.94032 18.4576 7.94624L10.8305 15.6C10.431 16.0009 9.77724 16.0009 9.37772 15.6C8.97821 15.1991 8.97821 14.5431 9.37772 14.1421L16.2785 7.21731L15.1889 6.12392L8.28814 13.0487C7.28571 14.0547 7.28571 15.6875 8.28814 16.6934C9.29056 17.6993 10.9177 17.6993 11.9201 16.6934L19.5472 9.03964C21.1525 7.4287 21.1525 4.81913 19.5472 3.2082C17.9419 1.59727 15.3414 1.59727 13.7361 3.2082L4.65617 12.3198C2.44794 14.5358 2.44794 18.1221 4.65617 20.338C6.86441 22.554 10.4383 22.554 12.6465 20.338L21 11.9554L19.9104 10.862Z\" fill=\"#7c849b\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});