define("ember-svg-jar/inlined/calendar-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12.6667 2.66659H12V1.33325H10.6667V2.66659H5.33333V1.33325H4V2.66659H3.33333C2.59333 2.66659 2.00667 3.26659 2.00667 3.99992L2 13.3333C2 14.0666 2.59333 14.6666 3.33333 14.6666H12.6667C13.4 14.6666 14 14.0666 14 13.3333V3.99992C14 3.26659 13.4 2.66659 12.6667 2.66659ZM12.6667 5.99992V13.3333H3.33333V5.99992H12.6667ZM8 7.33325H4.66667V10.6666H8V7.33325Z\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});