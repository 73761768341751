define("ember-svg-jar/inlined/plus-circle-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><clipPath clipPathUnits=\"userSpaceOnUse\"><path d=\"M0 960H1080V0H0Z\"/></clipPath></defs><g transform=\"translate(-61.316075,-91.464887)\"><g transform=\"matrix(0.35277777,0,0,-0.35277777,-306.14039,295.48816)\"><g clip-path=\"url(#clipPath882)\"><g transform=\"translate(1051.1689,565.6277)\"><path d=\"M0 0C0-0.189-0.073-0.368-0.208-0.502-0.342-0.636-0.52-0.71-0.71-0.709L-1.743-0.71c-0.189 0-0.368 0.074-0.502 0.208-0.134 0.135-0.208 0.313-0.207 0.503v3.145h-3.146c-0.19 0-0.368 0.074-0.502 0.208-0.135 0.134-0.208 0.313-0.208 0.502v1.033c0 0.189 0.074 0.367 0.208 0.502 0.134 0.134 0.312 0.208 0.502 0.207h3.146v3.147c0 0.189 0.073 0.367 0.208 0.501 0.134 0.134 0.312 0.208 0.502 0.208h1.032c0.19 0 0.368-0.073 0.502-0.207C-0.074 9.113 0 8.934 0 8.745V5.598H3.146C3.335 5.599 3.514 5.525 3.648 5.391 3.782 5.257 3.856 5.078 3.856 4.889V3.856C3.856 3.666 3.782 3.488 3.648 3.354 3.514 3.22 3.335 3.146 3.146 3.146H0Zm-1.226 12.706c-4.595 0-8.334-3.739-8.334-8.334 0-4.595 3.739-8.333 8.334-8.333 4.595 0 8.333 3.738 8.333 8.333 0 4.595-3.738 8.334-8.333 8.334\" style=\"fill-opacity:1;fill-rule:nonzero;\"/></g></g></g></g>",
    "attrs": {
      "xmlns:svg": "http://www.w3.org/2000/svg",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "5.8797469mm",
      "height": "5.8797469mm",
      "viewBox": "0 0 5.8797469 5.8797469",
      "version": "1.1"
    }
  };
});