define("ember-svg-jar/inlined/payments-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M14.25 10.5V4.5C14.25 3.675 13.575 3 12.75 3H2.25C1.425 3 0.75 3.675 0.75 4.5V10.5C0.75 11.325 1.425 12 2.25 12H12.75C13.575 12 14.25 11.325 14.25 10.5ZM7.5 9.75C6.255 9.75 5.25 8.745 5.25 7.5C5.25 6.255 6.255 5.25 7.5 5.25C8.745 5.25 9.75 6.255 9.75 7.5C9.75 8.745 8.745 9.75 7.5 9.75ZM17.25 5.25V13.5C17.25 14.325 16.575 15 15.75 15H3V13.5H15.75V5.25H17.25Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});