define("ember-svg-jar/inlined/dot-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<circle cx=\"5\" cy=\"5\" r=\"5\" fill=\"#0B80F3\"/>\n",
    "attrs": {
      "width": "10",
      "height": "10",
      "viewBox": "0 0 10 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});