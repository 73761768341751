define("ember-svg-jar/inlined/pin-vertical-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M15.1992 12V5.6H15.9992V4H7.99922V5.6H8.79922V12L7.19922 13.6V15.2H11.3592V20H12.6392V15.2H16.7992V13.6L15.1992 12Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});