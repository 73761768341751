define("ember-svg-jar/inlined/time-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.9935 1.33301C4.3135 1.33301 1.3335 4.31967 1.3335 7.99967C1.3335 11.6797 4.3135 14.6663 7.9935 14.6663C11.6802 14.6663 14.6668 11.6797 14.6668 7.99967C14.6668 4.31967 11.6802 1.33301 7.9935 1.33301ZM8.00016 13.333C5.0535 13.333 2.66683 10.9463 2.66683 7.99967C2.66683 5.05301 5.0535 2.66634 8.00016 2.66634C10.9468 2.66634 13.3335 5.05301 13.3335 7.99967C13.3335 10.9463 10.9468 13.333 8.00016 13.333ZM7.3335 4.66634H8.3335V8.16634L11.3335 9.94634L10.8335 10.7663L7.3335 8.66634V4.66634Z\"/>\n",
    "attrs": {
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});