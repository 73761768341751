define("ember-svg-jar/inlined/file-type-excel-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M8 0C5.79086 0 4 1.79086 4 4V36C4 38.2091 5.79086 40 8 40H32C34.2091 40 36 38.2091 36 36V12C36 11.6799 35.8728 11.3728 35.6464 11.1464L24.8536 0.353553C24.6272 0.127177 24.3201 0 24 0H8Z\" fill=\"white\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M32 38.5H8C6.61929 38.5 5.5 37.3807 5.5 36V4C5.5 2.61929 6.61929 1.5 8 1.5H23.25V8C23.25 10.6234 25.3766 12.75 28 12.75H34.5V36C34.5 37.3807 33.3807 38.5 32 38.5ZM33.6287 11.25L24.75 2.37132V8C24.75 9.79493 26.2051 11.25 28 11.25H33.6287ZM4 4C4 1.79086 5.79086 0 8 0H24C24.3201 0 24.6272 0.127177 24.8536 0.353553L35.6464 11.1464C35.8728 11.3728 36 11.6799 36 12V36C36 38.2091 34.2091 40 32 40H8C5.79086 40 4 38.2091 4 36V4Z\" fill=\"#CDD5DF\"/>\n<path d=\"M26.25 16.5H13.75C12.8333 16.5 12.0833 17.25 12.0833 18.1667V29.8333C12.0833 30.75 12.8333 31.5 13.75 31.5H26.25C27.1667 31.5 27.9167 30.75 27.9167 29.8333V18.1667C27.9167 17.25 27.1667 16.5 26.25 16.5ZM26.25 18.1667V20.6667H13.75V18.1667H26.25ZM22.0833 29.8333H17.9167V22.3333H22.0833V29.8333ZM13.75 22.3333H16.25V29.8333H13.75V22.3333ZM23.75 29.8333V22.3333H26.25V29.8333H23.75Z\" fill=\"#4F7A21\"/>\n",
    "attrs": {
      "width": "40",
      "height": "40",
      "viewBox": "0 0 40 40",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});