define("ember-svg-jar/inlined/map-marker-icon-alt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.16675 7.5C4.16675 4.275 6.77508 1.66666 10.0001 1.66666C13.2251 1.66666 15.8334 4.275 15.8334 7.5C15.8334 11.875 10.0001 18.3333 10.0001 18.3333C10.0001 18.3333 4.16675 11.875 4.16675 7.5ZM7.91675 7.5C7.91675 8.65 8.85008 9.58333 10.0001 9.58333C11.1501 9.58333 12.0834 8.65 12.0834 7.5C12.0834 6.35 11.1501 5.41666 10.0001 5.41666C8.85008 5.41666 7.91675 6.35 7.91675 7.5Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});