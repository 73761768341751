define("ember-svg-jar/inlined/no-assignee-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<rect width=\"26\" height=\"26\" rx=\"13\" fill=\"#EBEFF2\"/>\n<g opacity=\"0.08\">\n<rect x=\"0.25\" y=\"0.25\" width=\"25.5\" height=\"25.5\" rx=\"12.75\" stroke=\"#121926\" stroke-width=\"0.5\"/>\n</g>\n<path d=\"M15.1202 12.2935C15.2535 12.0002 15.3335 11.6802 15.3335 11.3335C15.3335 10.0468 14.2868 9.00016 13.0002 9.00016C12.6535 9.00016 12.3335 9.08016 12.0402 9.2135L15.1202 12.2935Z\" fill=\"#4B5565\"/>\n<path d=\"M13.0002 6.3335C9.32016 6.3335 6.3335 9.32016 6.3335 13.0002C6.3335 16.6802 9.32016 19.6668 13.0002 19.6668C16.6802 19.6668 19.6668 16.6802 19.6668 13.0002C19.6668 9.32016 16.6802 6.3335 13.0002 6.3335ZM13.0002 15.0002C11.4535 15.0002 10.0335 15.5335 8.90683 16.4135C8.1335 15.4868 7.66683 14.3002 7.66683 13.0002C7.66683 11.7668 8.08683 10.6335 8.7935 9.7335L10.7002 11.6402C10.8402 12.6802 11.6535 13.5002 12.6935 13.6335L14.1602 15.1002C13.7802 15.0335 13.3935 15.0002 13.0002 15.0002ZM17.2068 16.2668L9.7335 8.7935C10.6335 8.08683 11.7668 7.66683 13.0002 7.66683C15.9468 7.66683 18.3335 10.0535 18.3335 13.0002C18.3335 14.2335 17.9135 15.3602 17.2068 16.2668Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});