define("ember-svg-jar/inlined/th-large-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M2.684 3.194H1.7a.246.246 0 0 1-.246-.246v-.983c0-.135.11-.245.246-.245h.983c.135 0 .245.11.245.246v.982a.246.246 0 0 1-.245.246zm0 1.965H1.7a.246.246 0 0 1-.246-.246v-.982c0-.136.11-.246.246-.246h.983c.135 0 .245.11.245.246v.982a.246.246 0 0 1-.245.246zm1.965-1.965h-.983a.246.246 0 0 1-.245-.246v-.983c0-.135.11-.245.245-.245h.983c.136 0 .246.11.246.246v.982a.246.246 0 0 1-.246.246zm0 1.965h-.983a.246.246 0 0 1-.245-.246v-.982c0-.136.11-.246.245-.246h.983c.136 0 .246.11.246.246v.982a.246.246 0 0 1-.246.246z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 6.35 6.614"
    }
  };
});