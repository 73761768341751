define("ember-svg-jar/inlined/checkbox-circle-custom-on", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0ZM18.8837 8.38381C19.3719 7.89567 19.3719 7.10425 18.8837 6.61611C18.3956 6.12798 17.6042 6.12798 17.116 6.61611L9.7499 13.9823L6.88374 11.1161C6.39561 10.628 5.60418 10.628 5.11605 11.1161C4.62791 11.6042 4.62791 12.3957 5.11605 12.8838L8.86605 16.6338C9.35418 17.1219 10.1456 17.1219 10.6337 16.6338L18.8837 8.38381Z\" fill=\"#328923\"/>\n",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});