define("ember-svg-jar/inlined/label-outline-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.6842 4.63159C16.3895 4.63159 17.0211 4.97896 17.4 5.5158L22 12L17.4 18.4842C17.0211 19.0211 16.3895 19.3684 15.6842 19.3684L4.10526 19.3579C2.94737 19.3579 2 18.4211 2 17.2632V6.73686C2 5.57896 2.94737 4.64212 4.10526 4.64212L15.6842 4.63159ZM4.10526 17.2632H15.6842L19.4211 12L15.6842 6.73686H4.10526V17.2632Z\"/>",
    "attrs": {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});