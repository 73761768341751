define("ember-svg-jar/inlined/old-list-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M5.88 4.85H2.204v.589h3.674zm0-2.204H2.204v.588h3.674zm0-2.205H2.204v.588h3.674zM1.47 5.17v-.05a.71.71 0 0 0-.71-.71H.71a.71.71 0 0 0-.71.71v.05c0 .392.318.71.71.71h.05a.71.71 0 0 0 .71-.71m0-2.205v-.05a.71.71 0 0 0-.71-.71H.71a.71.71 0 0 0-.71.71v.05c0 .392.318.71.71.71h.05a.71.71 0 0 0 .71-.71M.76 1.47H.71A.71.71 0 0 1 0 .76V.71A.71.71 0 0 1 .71 0h.05a.71.71 0 0 1 .71.71v.05a.71.71 0 0 1-.71.71\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 5.8793912 5.8793898",
      "height": "22.221",
      "width": "22.221"
    }
  };
});