define("ember-svg-jar/inlined/dollar-filled-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.3332 9.99984C18.3332 5.39984 14.5998 1.6665 9.99984 1.6665C5.39984 1.6665 1.6665 5.39984 1.6665 9.99984C1.6665 14.5998 5.39984 18.3332 9.99984 18.3332C14.5998 18.3332 18.3332 14.5998 18.3332 9.99984ZM10.8812 14.9998V13.806C11.9687 13.5998 12.8249 12.9748 12.8312 11.831C12.8312 10.256 11.4812 9.71228 10.2187 9.38729C8.95621 9.06229 8.54996 8.71854 8.54996 8.19354C8.54996 7.59355 9.11246 7.16855 10.05 7.16855C11.0374 7.16855 11.4062 7.64355 11.4374 8.33729H12.6624C12.6249 7.38105 12.0437 6.4998 10.8812 6.21855V4.99981H9.21246V6.20605C8.13747 6.4373 7.26872 7.1373 7.26872 8.21229C7.26872 9.49353 8.33121 10.131 9.88121 10.506C11.2749 10.8373 11.5499 11.3248 11.5499 11.8435C11.5499 12.2248 11.2749 12.8373 10.05 12.8373C8.90621 12.8373 8.45621 12.3248 8.39371 11.6685H7.16872C7.23747 12.881 8.14372 13.5685 9.21246 13.7935V14.9998H10.8812Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});