define("ember-svg-jar/inlined/remove-job-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5H15C15.3978 4.5 15.7794 4.65804 16.0607 4.93934C16.342 5.22064 16.5 5.60218 16.5 6V8.75522C15.8181 8.43127 15.0552 8.25 14.25 8.25C11.3505 8.25 9 10.6005 9 13.5C9 14.3052 9.18127 15.0681 9.50522 15.75H3C2.60218 15.75 2.22064 15.592 1.93934 15.3107C1.65804 15.0294 1.5 14.6478 1.5 14.25V6C1.5 5.1675 2.1675 4.5 3 4.5H6V3C6 2.1675 6.6675 1.5 7.5 1.5ZM10.5 4.5V3H7.5V4.5H10.5ZM16.0405 10.8634L17.1473 11.9702L15.4871 13.6304L17.1473 15.2905L16.0405 16.3973L14.3804 14.7371L12.7202 16.3973L11.6134 15.2905L13.2736 13.6304L11.6134 11.9702L12.7202 10.8634L14.3804 12.5236L16.0405 10.8634Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});