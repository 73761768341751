define("ember-svg-jar/inlined/send-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.67508 17.5L19.1667 10L1.67508 2.5L1.66675 8.33333L14.1667 10L1.66675 11.6667L1.67508 17.5Z\"/>",
    "attrs": {
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});