define("ember-svg-jar/inlined/salesperson-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M12.9993 2.16675C7.01935 2.16675 2.16602 7.02008 2.16602 13.0001C2.16602 18.9801 7.01935 23.8334 12.9993 23.8334C18.9793 23.8334 23.8327 18.9801 23.8327 13.0001C23.8327 7.02008 18.9793 2.16675 12.9993 2.16675ZM16.9102 9.03508C18.0694 9.03508 19.001 9.96675 19.001 11.1259C19.001 12.2851 18.0694 13.2167 16.9102 13.2167C15.751 13.2167 14.8193 12.2851 14.8193 11.1259C14.8085 9.96675 15.751 9.03508 16.9102 9.03508ZM10.4102 7.32342C11.8185 7.32342 12.9668 8.47175 12.9668 9.88008C12.9668 11.2884 11.8185 12.4367 10.4102 12.4367C9.00185 12.4367 7.85352 11.2884 7.85352 9.88008C7.85352 8.46092 8.99102 7.32342 10.4102 7.32342ZM10.4102 17.2142V21.2767C7.81018 20.4642 5.75185 18.4601 4.84185 15.9034C5.97935 14.6901 8.81768 14.0726 10.4102 14.0726C10.9844 14.0726 11.7102 14.1592 12.4685 14.3109C10.6918 15.2534 10.4102 16.4992 10.4102 17.2142ZM12.9993 21.6667C12.7068 21.6667 12.4252 21.6559 12.1435 21.6234V17.2142C12.1435 15.6759 15.3285 14.9067 16.9102 14.9067C18.0694 14.9067 20.0735 15.3292 21.0702 16.1526C19.8027 19.3701 16.6718 21.6667 12.9993 21.6667Z\" fill=\"#4B5565\"/>\n",
    "attrs": {
      "viewBox": "0 0 26 26",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});