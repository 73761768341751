define("ember-svg-jar/inlined/catalyst-copy-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "\n<path d=\"M16.0263 2H5.28947C4.30526 2 3.5 2.81818 3.5 3.81818V16.5455H5.28947V3.81818H16.0263V2Z\" fill=\"#7C849B\"/>\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.7105 5.63636H8.86842C7.88421 5.63636 7.07895 6.45455 7.07895 7.45455V20.1818C7.07895 21.1818 7.88421 22 8.86842 22H18.7105C19.6947 22 20.5 21.1818 20.5 20.1818V7.45455C20.5 6.45455 19.6947 5.63636 18.7105 5.63636ZM8.86842 20.1818H18.7105V7.45455H8.86842V20.1818Z\" fill=\"#7C849B\"/>\n",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});